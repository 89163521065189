import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import Layout from "../components/Layout";
import SectionLayout from "../components/SectionLayout";
import SEO from "../components/seo";

const StyledImg = styled(Img)`
  min-width: 1px;
  min-height: 300px;
  height: 100%;
  margin: 2em 0 3em 0;
  box-sizing: border-box;

  > img {
    height: 100%;
    width: 100%;
  }
  border-radius: 3px;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
`;

const Paragraph = styled.p`
  margin-bottom: 2em;
  text-transform: none;
  font-size: 16px;
`;

const StyledH1 = styled.h1`
  font-weight: bold;
  margin-bottom: 2rem;
`;

class About extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Loceye | Understanding Humans"
          keywords={[`loceye`, `eyetracking`, `marketing`, `ux`, "career"]}
        />
        <SectionLayout title={"Our Purpose"}>
          <div
            className="container"
            style={{ marginBottom: "1em", textTransform: "capitalize" }}
          >
            <div className="row">
              <div
                className="offset-md-2 col-md-8 col-sm-12"
                style={{ height: "100%" }}
              >
                <StyledImg fluid={data.team.childImageSharp.fluid} />
              </div>
            </div>
            <div className="row">
              <div className="offset-md-2 col-md-8 col-sm-12">
                <Paragraph>
                  Loceye started with a mission to help content creators design
                  with guidance and with human on the center. We are passionate
                  about providing the best experience to everyone that can
                  benefit from our technology and provide them the tools they
                  need in order to increase the visual impact of their designs
                  to their maximum.
                </Paragraph>
                <Paragraph>
                  Since 2018, we have created state of the art technology. We
                  use web-based eye tracking to provide insights to brands,
                  agencies, and market researchers. As a consultancy service, we
                  remain dedicated in our mission to make eye tracking more
                  accessible and help companies evolve their Design Processes
                  and Guidelines.
                </Paragraph>
                <Paragraph>
                  To learn how Loceye can improve your design process and
                  increase conversion arrange to speak to one of our experts.
                </Paragraph>
                <p className="lead" />
                <Link to="/contact">
                  <a className="btn btn--sm btn--primary type--uppercase">
                    <span className="btn__text"> Speak to an expert</span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </SectionLayout>
      </Layout>
    );
  }
}

export default About;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    team: file(relativePath: { regex: "/about.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
    espa: file(relativePath: { regex: "/espa.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 80) {
          srcSet
          base64
          src
        }
      }
    }
  }
`;
